import { TeliaEvent } from "@telia/b2x-google-analytics";

declare global {
  interface Window {
    next?: {
      router: unknown;
    };
    blueConicClient?: {
      handlePageView: () => void;
    };
    dataLayer?: TeliaEvent[];
    whisbi?: {
      openWidget?: (options: { action: string }) => void;
    };
  }
}

export const inject = (src: string) => {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  document.head.appendChild(script);
};

export const w: Window | undefined = typeof window !== "undefined" ? window : undefined;
export const getWindow = () => (typeof window !== "undefined" ? window : undefined);
export const isB2B = () => w?.location?.pathname?.startsWith("/foretag");
export const isB2C = () => !isB2B();
export const isPublic = () => !startsWith(["/mitt-telia", "/foretag/mybusiness"]);

export const startsWith = (segment: string[]) => {
  return segment.some((pathname) => w?.location?.pathname?.startsWith(pathname));
};
