import { onceConsented } from "@telia/b2x-cookie-consent";
import {
  inject as injectMedallia,
  virtualPageView as virtualPageViewMedallia,
} from "@telia/b2x-medallia";
import { inject as injectMedalliaDXA } from "@telia/b2x-medallia-dxa";
import { onVirtualPageView } from "./virtual-page-view";
import { MEDALLIA_ID } from "../constants";
import { rule } from "@telia/b2x-url-segments";
import { w } from "../util";

export const initMedallia = () => {
  onVirtualPageView(virtualPageViewMedallia);

  onceConsented("C0002", "Follow user session and launch surveys via medallia").then(() => {
    if (w) {
      const r = rule(new URL(window.location.href));
      let medallia = true;

      if (r.pathStartsWith(["/foretag/mybusiness", "/mitt-telia/whisbi-support"])) {
        medallia = false;
      }

      // include individual paths from what excluded above
      const isPathIncluded =
        r.pathEndsWith(["/foretag/mybusiness/start"]) ||
        (r.pathStartsWith(["/foretag/mybusiness"]) &&
          (r.pathEndsWith([
            "/bestall/formaner",
            "/mobilabonnemang",
            "/mobiltelefoner",
            "/tillbehor",
            "/bredband",
            "/emn",
            "/touchpoint-plus-eftermarknad",
          ]) ||
            r.pathIncludes([
              "/din-bestallning",
              "/mobil-telefoni/ny",
              "/mobil-telefoni/detaljer",
              "/mobilt-bredband/ny",
              "/mobilt-bredband/detaljer",
            ])));

      if (isPathIncluded) {
        medallia = true;
      }

      const dxa = !r.pathStartsWith(["/foretag/mybusiness", "/mitt-telia"]);

      if (medallia) {
        injectMedallia(MEDALLIA_ID);
      }

      if (dxa) {
        injectMedalliaDXA();
      }
    }
  });
};
