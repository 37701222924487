type VirtualPageViewCallback = (newUrl: string, oldUrl: string) => void;

let listening = false;

export const onVirtualPageView = (callback: VirtualPageViewCallback) => {
  if (listening || typeof window === "undefined") return;
  listening = true;

  // Single Spa
  window.addEventListener("single-spa:routing-event", (event) => {
    const { oldUrl, newUrl } = (event as CustomEvent).detail;
    if (oldUrl !== newUrl) {
      callback(newUrl, oldUrl);
    }
  });
};
