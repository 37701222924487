import { onceConsented } from "@telia/b2x-cookie-consent";

import { inject } from "../util";
import { BLUECONIC_B2B_SRC, BLUECONIC_B2C_SRC } from "../constants";
import { rule } from "@telia/b2x-url-segments";
import { onVirtualPageView } from "./virtual-page-view";
import { w } from "../util";

export const initBlueConic = () => {
  onVirtualPageView(handleBlueConicPageView);

  onceConsented("C0002", "BlueConic for Personalization").then(() => {
    if (w) {
      const r = rule(new URL(w.location.href));
      const src = r.pathStartsWith(["/foretag"]) ? BLUECONIC_B2B_SRC : BLUECONIC_B2C_SRC;
      inject(src);
    }
  });
};

export const handleBlueConicPageView = () => {
  if (w?.blueConicClient) {
    w.blueConicClient.handlePageView();
  }
};
