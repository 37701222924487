import { track } from "@telia/b2x-google-analytics";
import { onCoreWebVitals } from "@telia/b2x-core-web-vitals";
import { CORE_WEB_VITALS_EVENT_NAME, CORE_WEB_VITALS_SAMPLE_RATE } from "../constants";

export const initCoreWebVitals = () => {
  // Sample core web vitals measurements
  if (Math.random() < CORE_WEB_VITALS_SAMPLE_RATE) {
    onCoreWebVitals((webVitalsMeasurement) => {
      track(CORE_WEB_VITALS_EVENT_NAME, {
        webVitalsMeasurement,
      });
    });
  }
};
