import { onceConsented } from "@telia/b2x-cookie-consent";
import { inject } from "@telia/b2x-giosg";
import { rule } from "@telia/b2x-url-segments";
import { w } from "../util";
import { GIOSG_ACCOUNT_ID, WHISBI_LAUNCH_FROM_CUSTOMER_REP_CHAT_EVENT_NAME } from "../constants";

export const initGiosg = () => {
  // Open Whisbi upon Ace AI TV Chat custom event.
  window.addEventListener(WHISBI_LAUNCH_FROM_CUSTOMER_REP_CHAT_EVENT_NAME, () => {
    window.whisbi?.openWidget && window.whisbi.openWidget({ action: "oneToOneWidget" });
  });

  if (w) {
    const r = rule(new URL(w.location.href));

    if (r.isPublic || r.pathStartsWith(["/mitt-telia/whisbi-support"])) {
      onceConsented("C0001", "Enable Giosg").then(() => inject(GIOSG_ACCOUNT_ID));
    }
  }
};
