import { onceConsented } from "@telia/b2x-cookie-consent";
import { inject } from "@telia/b2x-optimizely";
import { rule } from "@telia/b2x-url-segments";
import { w } from "../util";

export const initOptimizely = () => {
  onceConsented("C0003", "Enable Optimizely").then(() => {
    if (w) {
      const r = rule(new URL(w.location.href));

      if (!r.pathStartsWith(["/mitt-telia", "/foretag/mybusiness"])) {
        inject();
      }
    }
  });
};
